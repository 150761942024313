import { useTranslation } from "react-i18next";

import { TEXT_COLOR } from "../../styles/colors";

import Styled from "./index.styles";

export type loadingDirection = "row" | "column";

export default function Loading({
  direction,
  size = "default",
  message,
  messageColor = TEXT_COLOR.black_3,
  className,
}: {
  direction: loadingDirection;
  // TODO: 추후 추가될 수 있음
  size?: "small" | "default" | "large";
  message?: string;
  messageColor?: string;
  className?: string;
}) {
  const { t } = useTranslation(["sds-v2"]);

  const loadingMessage = message ?? t("sds-v2:Loading.잠시만_기다려주세요...");

  return (
    <Styled.container
      className={`${className ? className : ""} loading`}
      direction={direction}
    >
      <svg height={24} width={24} viewBox="0 0 24 24">
        <circle cx="12.5" cy="12" r="11" strokeWidth={2} fill="none" />
      </svg>

      {typeof loadingMessage === "string" ? (
        <Styled.message color={messageColor}>{loadingMessage}</Styled.message>
      ) : (
        loadingMessage
      )}
    </Styled.container>
  );
}
