import { useState } from "react";
import { useTranslation } from "react-i18next";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import {
  InputSelectOption,
  InputSelectProps,
} from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import { useDeviceDetect } from "@sellernote/_shared/src/utils/common/hook";

import Styled from "./index.styles";

export default function InputSelect<T>({
  uiType,
  optionList,
  label,
  selectedOption,
  handleSelect,
  placeholder,
  disabled,
  note,
  warningMessage,
  width,
  errorMessage,
  isRequired,
  className,
}: InputSelectProps<T>) {
  const { t } = useTranslation();

  const { isMobile } = useDeviceDetect();

  const [isOpen, setIsOpen] = useState(false);

  function handleSelectOption(v: InputSelectOption<T>) {
    handleSelect(v);
    setIsOpen(false);
  }

  return (
    <Styled.container
      className={`${className ? className : ""} ${
        disabled ? "disabled" : ""
      } input-select`}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      {...(isMobile
        ? {
            onPointerLeaveCapture: () => {
              setIsOpen(false);
            },
          }
        : {})}
      width={width}
    >
      {label && (
        <div className="label">
          {label}
          {isRequired && <em> (*{t("sds-v1:InputSelect.필수")})</em>}
        </div>
      )}

      <Styled.selected
        onClick={() => {
          if (!disabled) {
            setIsOpen(true);
          }
        }}
        className={`${isOpen ? "opened" : ""} ${disabled ? "disabled" : ""}`}
        uiType={uiType}
      >
        <div className={`value ${selectedOption ? "has-value" : ""}`}>
          {selectedOption?.label ||
            placeholder ||
            t("sds-v1:InputSelect.선택해주세요.")}
        </div>

        {errorMessage ? (
          <Icon type="warning" size={1} color={COLOR.pointWarning} />
        ) : (
          <Icon
            type={isOpen ? "caretUp" : "caretDown"}
            size={1}
            color={isOpen ? COLOR.primaryBlue : COLOR.grayScale_400}
          />
        )}
      </Styled.selected>

      {(warningMessage || errorMessage) && (
        <div className="warning-message-wrapper">
          <div className="warning-message">
            {errorMessage || warningMessage}
          </div>
        </div>
      )}

      <div className="option-list-wrapper">
        {isOpen && (
          <Styled.optionList>
            {optionList.map((v, i) => {
              return (
                <div
                  className={`${v.disabled ? "disabled" : ""} item`}
                  key={i}
                  onClick={v.disabled ? noop : () => handleSelectOption(v)}
                >
                  <div className="label">{v.label}</div>

                  {v.desc && <div className="desc">{v.desc}</div>}
                </div>
              );
            })}
          </Styled.optionList>
        )}
      </div>

      {note && <div className="note-wrapper">{note}</div>}
    </Styled.container>
  );
}
