import { Dispatch, SetStateAction } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import Styled from "./index.styles";

export default function CustomCalender({
  data,
  visibleHolidayGuide,
  setVisibleHolidayGuide,
  setIsChangedMonth,
}: {
  data: {
    date: Date;
    decreaseMonth(): void;
    increaseMonth(): void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  };
  visibleHolidayGuide: boolean;
  setVisibleHolidayGuide: Dispatch<SetStateAction<boolean>>;
  setIsChangedMonth: Dispatch<SetStateAction<boolean>>;
}) {
  function formatDate(selectedDate: Date) {
    const date = new Date(selectedDate);
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();

    return ShipdaCurrentLanguage.currentLanguage === "ko"
      ? `${year}년 ${`0${monthIndex}`.slice(-2)}월`
      : `${date.toLocaleDateString("en-US", { month: "long" })} ${year}`;
  }

  return (
    <>
      <Styled.calendarHeader>
        <span className="selected-month">{formatDate(data.date)}</span>

        <div className="select-icon-container">
          <Icon
            type="arrowLeft"
            color={COLOR.primaryBlue}
            onClick={() => {
              data.decreaseMonth();
              setIsChangedMonth(true);
            }}
            size={1.8}
          />
          <Icon
            type="arrowRight"
            color={COLOR.primaryBlue}
            onClick={() => {
              data.increaseMonth();
              setIsChangedMonth(true);
            }}
            size={1.8}
          />
        </div>
      </Styled.calendarHeader>

      <Modal
        uiType="titleOnly"
        active={visibleHolidayGuide}
        title="주말/공휴일 출고의 경우 기존 견적의 20% 할증됩니다."
        actionPositive={{
          label: "확인",
          handleClick: () => setVisibleHolidayGuide(false),
        }}
      />
    </>
  );
}
