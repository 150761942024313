import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const calendarWrapper = styled.div`
  .react-datepicker__tab-loop {
    position: relative;
  }
`;

const container = styled.div`
  display: flex;
  padding: 8px;
  background-color: ${COLOR.white};
  box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.12);

  .react-datepicker {
    border: 0;
  }

  .react-datepicker__header,
  .react-datepicker__time-container {
    background-color: #fff;
    border: 0;
  }

  .react-datepicker__header {
    padding-top: 0;
  }

  .react-datepicker__time-container {
    margin-left: 8px;
  }

  .react-datepicker__month {
    margin: 0;
    border-right: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;

    .sunday,
    .holiday {
      color: ${COLOR.pointWarning};
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 40px;
    height: 40px;
  }

  .react-datepicker__day-names {
    margin-top: 18px;

    > div:first-child {
      color: ${COLOR.pointWarning};
    }
  }

  .react-datepicker__day-name {
    ${setFontStyle("Body8", "Bold")};
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: ${COLOR.grayScale_800};
  }

  .react-datepicker__day {
    ${setFontStyle("Body8")};
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    color: ${COLOR.grayScale_800};
  }

  .react-datepicker__day:hover {
    border-radius: 0;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 0;
    background-color: ${COLOR.primaryBlue_40};
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day--disabled.sunday,
  .react-datepicker__day--disabled.holiday {
    color: ${COLOR.grayScale_400};
  }

  .react-datepicker__day--outside-month {
    background-color: ${COLOR.grayScale_100};
    color: ${COLOR.grayScale_600};
  }

  .icon {
    cursor: pointer;
  }

  .react-datepicker-time__header {
    display: flex;
    justify-content: flex-start;
    ${setFontStyle("Head6", "Bold")};
    color: ${COLOR.secondaryDeepBlue};
  }
`;

const calendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .selected-month {
    ${setFontStyle("Body4", "Bold")};
    color: ${COLOR.primaryBlue};
  }
`;

const selectTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;

  .title-time {
    ${setFontStyle("Head6", "Bold")};
    color: ${COLOR.secondaryDeepBlue};
    margin-bottom: 8px;
  }

  .input-select {
    width: 250px;
    margin-bottom: 16px;
  }
`;

export default {
  calendarWrapper,
  container,
  calendarHeader,
  selectTimeContainer,
};
